import React from 'react'
import Layout from '../components/Layout/layout'
import SEO from '../components/SEO/seo'
import { ValidateEmail } from '../components/ForgotPassword/ValidateEmail'
const ForgotPassword = () => {
	return (
		<Layout>
			<SEO title='Forgot Password' />
			<ValidateEmail />
		</Layout>
	)
}

export default ForgotPassword
